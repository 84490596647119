<template>
  <v-navigation-drawer
    v-if="options.customer"
    v-model="options.show"
    absolute
    floating
    temporary
    width="300"
    style="z-index: 5"
  >
    <v-icon
      size="96"
      class="customer-map-icon mt-5"
    >
      {{ `mdi-${options.customer.status_icon}` }}
    </v-icon>
    <h1 class="text-h3 mt-4 mb-1 text-center">
      <router-link
        :to="`/leads/${options.customer.user}-${options.customer.id}`"
        target="_blank"
      >
        {{ options.customer.name }}
      </router-link>
    </h1>
    <v-list dense>
      <v-list-item
        v-for="(list, i) in lists"
        :key="i"
      >
        <template v-if="list.content">
          <v-list-item-avatar>
            <v-icon size="25">
              {{ list.icon }}
            </v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            {{ list.content }}
          </v-list-item-content>
        </template>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  import moment from 'moment'

  export default {
    props: {
      options: {
        type: Object,
        default: () => ({}),
      },
    },

    computed: {
      lists () {
        if (this.options.customer) {
          return [
            {
              icon: 'mdi-priority-high',
              content: this.options.customer.priority_type || '',
            },
            {
              icon: '$listStatus',
              content: this.options.customer.status_type || '',
            },
            {
              icon: 'mdi-calendar-refresh',
              content: this.options.customer.updated_at ? moment(this.options.customer.updated_at).format('YYYY-MM-DD') : '',
            },
            {
              icon: 'mdi-thermometer-alert',
              content: this.options.customer.temperature_type || '',
            },
          ]
        }

        return []
      },
    },
  }
</script>

<style lang="sass" scoped>
.customer-map-icon
  text-align: center
  width: 100%
</style>
